import axios from "axios";
import { store } from "../Store/store";
import { updateUserData, clearUserData } from "Store/Slices/UserSlice";
import { authServices } from "services/AuthServices";

const timeZoneOffset = new Date().getTimezoneOffset() * -1;
const SHELL_APIS = process.env.REACT_APP_BASE_URL_API;
const SHELL_AUTH = process.env.REACT_APP_BASE_URL_AUTH;
const SHELL_ACF = process.env.REACT_APP_BASE_URL_ACF;

const axios_API = axios.create({
  baseURL: SHELL_APIS,
  timeout: 900000, // temp fix for pre-production, production, staging old is 120000
});

axios_API.interceptors.request.use(
  (config) => {
    const token = store.getState().user?.data?.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["TimeZoneOffset"] = timeZoneOffset;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios_API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = store.getState().user?.data?.refreshToken;
    const user = store.getState().user?.data;
    // set in the redux store the new user data from  success response and recall last API
    if (error?.response?.status === 401) {
      try {
        const response = await authServices.getNewAccessToken(refreshToken);

        if (response?.data) {
          store.dispatch(
            updateUserData({
              ...user,
              token: response?.data?.accessToken,
              refreshToken: response?.data?.refreshToken,
              isLoggedIn: true,
            })
          );
          const originalRequest = error.config;
          return axios_API(originalRequest);
        }
      } catch (error) {
        store.dispatch(clearUserData());
      }
    }
    // 403 should navigate user to 404 screen for forbidden
    //else if (error.response?.status === 403) {
    // Handle navigate the user
    //return Promise.reject("Access Forbidden");
    //}
    else {
      let errorBody = "";
      if (error?.response) {
        errorBody = {
          code: error.response.status,
          ...error?.response?.data,
        };
      }

      return Promise.reject(
        (error.response && errorBody) ||
          "Unexpected Error, Please Contact Your Administrator."
      );
    }
  }
);

const axios_AUTH = axios.create({
  baseURL: SHELL_AUTH,
  timeout: 120000,
});

axios_AUTH.interceptors.request.use(
  (config) => {
    const token = store.getState().user?.data?.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["TimeZoneOffset"] = timeZoneOffset;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios_AUTH.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = store.getState().user?.data?.refreshToken;
    const user = store.getState().user?.data;
    // set in the redux store the new user data from  success response and recall last API
    if (error?.response?.status === 401) {
      try {
        const response = await authServices.getNewAccessToken(refreshToken);

        if (response?.data) {
          store.dispatch(
            updateUserData({
              ...user,
              token: response?.data?.accessToken,
              refreshToken: response?.data?.refreshToken,
              isLoggedIn: true,
            })
          );
          const originalRequest = error.config;
          return axios_API(originalRequest);
        }
      } catch (error) {
        store.dispatch(clearUserData());
      }
    }

    // 403 should navigate user to 404 screen for forbidden
    //else if (error.response?.status === 403) {
    // Handle navigate the user
    //return Promise.reject("Access Forbidden");
    //}
    else {
      let errorBody = "";
      if (error?.response) {
        errorBody = {
          code: error.response.status,
          ...error?.response?.data,
        };
      }
      return Promise.reject(
        (error.response && errorBody) ||
          "Unexpected Error, Please Contact Your Administrator."
      );
    }
  }
);

const axios_ACF = axios.create({
  baseURL: SHELL_ACF,
  timeout: 900000, // temp fix for pre-production, production, staging old is 120000
});

axios_ACF.interceptors.request.use(
  (config) => {
    const token = store.getState().user?.data?.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["TimeZoneOffset"] = timeZoneOffset;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios_ACF.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = store.getState().user?.data?.refreshToken;
    const user = store.getState().user?.data;
    // set in the redux store the new user data from  success response and recall last API
    if (error?.response?.status === 401) {
      try {
        const response = await authServices.getNewAccessToken(refreshToken);

        if (response?.data) {
          store.dispatch(
            updateUserData({
              ...user,
              token: response?.data?.accessToken,
              refreshToken: response?.data?.refreshToken,
              isLoggedIn: true,
            })
          );
          const originalRequest = error.config;
          return axios_API(originalRequest);
        }
      } catch (error) {
        store.dispatch(clearUserData());
      }
    }
    // 403 should navigate user to 404 screen for forbidden
    //else if (error.response?.status === 403) {
    // Handle navigate the user
    //return Promise.reject("Access Forbidden");
    //}
    else {
      let errorBody = "";
      if (error?.response) {
        errorBody = {
          code: error.response.status,
          ...error?.response?.data,
        };
      }

      return Promise.reject(
        (error.response && errorBody) ||
          "Unexpected Error, Please Contact Your Administrator."
      );
    }
  }
);

const simpleErrorHandling = (err, defaultText = "") => {
  if (err?.message) {
    throw err;
  } else {
    throw new Error(defaultText);
  }
};
export { axios_AUTH, axios_ACF, simpleErrorHandling };
export default axios_API;
